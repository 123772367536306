.com-fake-comment {
  position: relative;
}

.com-fake-comment .inner {
  padding: 0.2rem;
  padding-top: 0.8rem;
  background-color: #fff;
  border: solid 0.05rem #f8dce5;
  border-radius: 0.1rem;
}

.com-fake-comment .inner .title {
  position: absolute;
  top: 0;
  left: 50%;
  width: 5rem;
  margin-left: -2.5rem;
  padding: 0.1rem 0;
  color: #fff;
  font-weight: bold;
  font-size: 0.42rem;
  line-height: 0.6rem;
  text-align: center;
  background-color: #c73a40;
  border-radius: 0 0 0.4rem 0.4rem;
}

.com-fake-comment .inner .tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.com-fake-comment .inner .tags .tag {
  display: block;
  width: 30%;
  margin: 0.1rem;
  color: #c73a40;
  font-size: 0.24rem;
  line-height: 0.4rem;
  text-align: center;
  border: solid 0.02rem #c73a40;
  border-radius: 0.05rem;
}

.com-fake-comment .inner .list {
  position: relative;
}

.com-fake-comment .inner .list .comment {
  position: relative;
  margin-bottom: 0.4rem;
  border-bottom: solid 0.02rem #ccc;
}

.com-fake-comment .inner .list .comment .head {
  position: relative;
  display: flex;
  margin-bottom: 0.2rem;
}

.com-fake-comment .inner .list .comment .head .avatar {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.4rem;
}

.com-fake-comment .inner .list .comment .head .avatar img {
  display: block;
  width: 0.8rem;
  border-radius: 0.4rem;
}

.com-fake-comment .inner .list .comment .head .name {
  margin-left: 0.2rem;
  font-size: 0.28rem;
  line-height: 0.8rem;
}

.com-fake-comment .inner .list .comment .head .time {
  position: absolute;
  right: 0;
  font-size: 0.24rem;
  line-height: 0.8rem;
}

.com-fake-comment .inner .list .comment .desc {
  margin-bottom: 0.2rem;
  font-size: 0.28rem;
}

.com-fake-comment .inner .list .comment .data {
  display: flex;
  justify-content: space-between;
}

.com-fake-comment .inner .list .comment .data .liulan {
  color: #888;
}

.com-fake-comment .inner .list .comment .data .zan {
  position: relative;
  padding-left: 0.3rem;
  color: #c73a40;
  line-height: 0.4rem;
}

.com-fake-comment .inner .list .comment .data .zan::before {
  position: absolute;
  top: 0.09rem;
  left: 0;
  width: 0.22rem;
  height: 0.22rem;
  background: url("./imgs/zan.png") no-repeat 50%/cover;
  content: '';
}

.com-fake-comment .scroller {
  position: relative;
  height: 8rem;
  overflow: hidden;
}

.com-fake-comment .scroller .w1 {
  position: absolute;
  top: 0;
  animation: scrollerw1 40s linear infinite;
}

.com-fake-comment .scroller .w2 {
  position: absolute;
  top: 8rem;
  animation: scrollerw1 40s linear 30.57s infinite;
}

@keyframes scrollerw1 {
  0% {
    transform: translateY(0);
  }

  99.999999% {
    transform: translateY(-33.94rem);
  }

  100% {
    transform: translateY(0);
  }
}