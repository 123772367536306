.com-fake-comment-lite {
  position: relative;
  padding: 0.2rem;
  background-color: #fee7ee;
  border-radius: 0.1rem;
}

.com-fake-comment-lite .marquee {
  position: relative;
  height: 4rem;
  overflow: hidden;
}

.com-fake-comment-lite .marquee .inner {
  animation: marquee-comment 30s linear infinite;
}

.com-fake-comment-lite .marquee .item {
  display: flex;
}

.com-fake-comment-lite .marquee .item span {
  display: block;
  flex: 1;
  color: #666;
  font-size: 0.26rem;
  line-height: 0.6rem;
  text-align: center;
}

@keyframes marquee-comment {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-100%);
  }
}