.page-result {
  background-color: #e6d5d3;
}

.page-result .body {
  padding: 0;
  padding-bottom: 0.5rem;
}

.page-result .body .banner img {
  display: block;
  width: 100%;
}

.page-result .body .names {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem;
}

.page-result .body .names .man {
  width: 2.96rem;
  height: 4.3rem;
}

.page-result .body .names .man .name {
  width: 100%;
  padding: 0.3rem 0.1rem;
  color: #000;
  font-weight: bold;
  font-size: 0.32rem;
  text-align: center;
}

.page-result .body .names .man.male {
  background: url("./imgs/male.png") no-repeat 50%/cover;
}

.page-result .body .names .man.female {
  background: url("./imgs/female.png") no-repeat 50%/cover;
}

.page-result .body .names .heart {
  width: 0.98rem;
  height: 0.46rem;
  background: url("./imgs/icon-heart.png") no-repeat 50%/cover;
}

.page-result .body .price {
  color: #020202;
  font-weight: bold;
  font-size: 0.28rem;
  text-align: center;
}

.page-result .body .price em {
  color: #e93323;
  font-size: 0.5rem;
  font-style: normal;
}

.page-result .body .countdown {
  padding: 0.1rem 0;
  color: #000;
  font-size: 0.28rem;
  text-align: center;
}

.page-result .body .pay-button {
  padding: 0 0.4rem;
}

.page-result .body .pay-button .btn {
  margin: 0.2rem 0;
  font-weight: bold;
  font-size: 0.4rem;
}

.page-result .body .pay-button .btn .icon {
  width: 0.6rem;
  height: 0.6rem;
  margin: 0 0.1rem;
}

.page-result .body .pay-button .btn.wechat {
  background-color: #07c160;
}

.page-result .body .pay-button .btn.wechat .icon {
  background: url("./imgs/icon-wechat.png") no-repeat 50%/cover;
}

.page-result .body .pay-button .btn.alipay {
  background-color: #1296db;
}

.page-result .body .pay-button .btn.alipay .icon {
  background: url("./imgs/icon-alipay.png") no-repeat 50%/cover;
}

.page-result .body .tip {
  margin: 0.1rem 0.2rem;
  padding: 0.2rem;
  color: #333;
  font-size: 0.28rem;
  background-color: #e3dbda;
  border-radius: 0.1rem;
}

.page-result .body .tip em {
  color: #e56767;
  font-style: normal;
}

.page-result .ads {
  position: relative;
  display: block;
  padding: 0 0.4rem 0.4rem 0.4rem;
  overflow: hidden;
  border-radius: 0.05rem;
}

.page-result .ads .ad {
  display: block;
  margin-bottom: 0.3rem;
}

.page-result .ads .ad.yinyuan {
  animation: sharke 2s linear infinite;
}

.page-result .ads .ad.hehun {
  animation: sharke 2s linear 1s infinite;
}

.page-result .ads .ad.qinggan {
  animation: sharke 2s linear 1.3s infinite;
}

.page-result .ads img {
  display: block;
  width: 100%;
  border-radius: 0.05rem;
}

.page-result .ads h2 {
  position: absolute;
  bottom: 0.3rem;
  width: 100%;
  color: #fff;
  font-size: 0.36rem;
  text-align: center;
}

@keyframes sharke {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.page-result .step .score {
  margin-bottom: 0.4rem;
  font-weight: bold;
  font-size: 0.4rem;
  text-align: center;
}

.page-result .step .score em {
  color: #d45a5a;
  font-weight: bold;
  font-size: 0.6rem;
}

.page-result .step .submit {
  width: 6.8rem;
  height: 2.25rem;
  margin: -0.4rem auto 0 auto;
  color: #d86a2b;
  font-weight: bold;
  font-size: 0.46rem;
  background: url("../../assets/imgs/bg-btn.png") no-repeat 50%/contain;
  animation: sharke 1s linear infinite;
}

@keyframes circleAnimationn {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}