.page-pay .body {
  padding: 0;
  padding-bottom: 1.6rem;
  background-color: #ffe5ea;
}

.page-pay .body .banner img {
  display: block;
  width: 100%;
}

.page-pay .body .names {
  position: relative;
  z-index: 9;
  width: 7.2rem;
  height: 8.24rem;
  margin: 0 auto;
  padding-top: 0.4rem;
  background: url("./imgs/name-bg@2x.png") no-repeat 50% 0/cover;
}

.page-pay .body .names .scores {
  position: absolute;
  bottom: 0.2rem;
  left: 50%;
  width: 5.8rem;
  margin-left: -2.9rem;
}

.page-pay .body .names .scores .circle {
  position: absolute;
  width: 1.46rem;
  height: 1.42rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.26rem;
  line-height: 1.42rem;
  text-align: center;
  text-shadow: 0 0 0.06rem #ff00eb;
  background: url("./imgs/circle@2x.png") no-repeat 50%/cover;
}

.page-pay .body .names .scores .circle.circle1 {
  bottom: 1.2rem;
  left: 0;
}

.page-pay .body .names .scores .circle.circle2 {
  bottom: 0.4rem;
  left: 50%;
  margin-left: -0.8rem;
}

.page-pay .body .names .scores .circle.circle3 {
  right: 0;
  bottom: 1.2rem;
}

.page-pay .body .names .scores .tip {
  position: relative;
  padding: 0 0.4rem;
  color: #d86a2b;
  font-weight: bold;
  font-size: 0.3rem;
  line-height: 0.48rem;
  text-align: center;
}

.page-pay .body .names .scores .tip::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 0.38rem;
  height: 0.48rem;
  background: url("./imgs/flower@2x.png") no-repeat 50%/0.38rem;
  content: '';
}

.page-pay .body .names .scores .tip::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.38rem;
  height: 0.48rem;
  background: url("./imgs/flower@2x.png") no-repeat 50%/0.38rem;
  content: '';
}

.page-pay .body .names .scores .star {
  position: absolute;
  bottom: 1.3rem;
  left: 50%;
  z-index: 9;
  width: 2.2rem;
  height: 2.2rem;
  margin-left: -1.16rem;
  padding-top: 0.6rem;
  background: url("./imgs/star-score@2x.png") no-repeat 50%/2.13rem 2.07rem;
}

.page-pay .body .names .scores .star .score {
  color: #fff;
  font-weight: 400;
  font-size: 0.56rem;
  line-height: 0.72rem;
  text-align: center;
  text-indent: -0.1rem;
  text-shadow: 0 0 0.06rem #ff00eb;
}

.page-pay .body .names .scores .star .score span {
  color: #fff;
  font-weight: 400;
  font-size: 0.56rem;
  text-shadow: 0 0 0.06rem #ff00eb;
}

.page-pay .body .names .scores .star .txt {
  margin-top: -0.05rem;
  color: #fff;
  font-size: 0.22rem;
  line-height: 0.3rem;
  text-align: center;
  text-indent: -0.08rem;
}

.page-pay .body .price {
  margin: 0.2rem 0.4rem 0.3rem 0.4rem;
  padding: 0.2rem;
  background-color: #fff;
  border-radius: 0.1rem;
}

.page-pay .body .price .discount {
  display: flex;
  justify-content: space-between;
  color: #9d5028;
}

.page-pay .body .price .discount .original {
  text-decoration: line-through;
}

.page-pay .body .price .discount span {
  color: inherit;
}

.page-pay .body .price .current {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 0.46rem;
}

.page-pay .body .price .current span {
  display: block;
  flex-shrink: 0;
  margin-right: 0.1rem;
  padding: 0.1rem;
  color: #fff;
  font-size: 0.2rem;
  background: #fd6622;
  border-radius: 0.26rem;
}

.page-pay .body .price .current em {
  color: #ef0c0c;
  font-weight: inherit;
  font-size: inherit;
  font-style: normal;
  letter-spacing: -0.01rem;
}

.page-pay .body .price .current .countdown {
  position: absolute;
  right: 0;
  color: #ef0c0c;
  font-weight: inherit;
  font-size: inherit;
}

.page-pay .body .pay-button {
  padding: 0 0.4rem;
}

.page-pay .body .pay-button .btn {
  height: 1.1rem;
  margin: 0.2rem 0;
  font-weight: bold;
  font-size: 0.4rem;
  border-radius: 0.55rem;
}

.page-pay .body .pay-button .btn .icon {
  width: 0.6rem;
  height: 0.6rem;
  margin: 0 0.1rem;
}

.page-pay .body .pay-button .btn.wechat {
  background-color: #07c160;
  animation: sharke 2s linear infinite;
}

.page-pay .body .pay-button .btn.wechat .icon {
  background: url("./imgs/icon-wechat.png") no-repeat 50%/cover;
}

.page-pay .body .pay-button .btn.alipay {
  background-color: #1296db;
  animation: sharke 2s linear 1.5s infinite;
}

.page-pay .body .pay-button .btn.alipay .icon {
  background: url("./imgs/icon-alipay.png") no-repeat 50%/cover;
}

.page-pay .body .tip {
  margin: 0.1rem 0.2rem;
  padding: 0.2rem;
  color: #333;
  font-size: 0.28rem;
  background-color: #e3dbda;
  border-radius: 0.1rem;
}

.page-pay .body .tip em {
  color: #e56767;
  font-style: normal;
}

.page-pay .body .pay-tip {
  height: 1.3rem;
  margin: 0 auto;
  color: #de5914;
  font-weight: bold;
  font-size: 0.38rem;
  line-height: 1.34rem;
  text-align: center;
}

.page-pay .body .result-list {
  padding: 0 0.2rem 0.4rem 0.2rem;
}

.page-pay .body .comment {
  padding: 0 0.4rem 0.4rem 0.4rem;
}

.page-pay .body .more {
  padding-bottom: 1rem;
}

.page-pay .body .more h2 {
  color: #f13b3b;
  font-size: 0.4rem;
  text-align: center;
}

.page-pay .body .more .cx {
  width: 3.78rem;
  height: 0.64rem;
  margin: 0 auto;
  margin-top: 0.2rem;
  background: url("./imgs/cxs.png") no-repeat 50%/cover;
}

.page-pay .body .more .company {
  color: #e56767;
  font-size: 0.2rem;
  text-align: center;
}

.page-pay .body .more .tip-warning {
  color: #b1afaf;
  font-size: 0.2rem;
  text-align: center;
}

.page-pay .fixed-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  background-color: rgba(152,50,5,0.9);
}

.page-pay .fixed-btn .fixed-submit {
  width: 7.5rem;
  height: 2.25rem;
  margin: 0 auto;
  color: #d86a2b;
  font-weight: bold;
  font-size: 0.46rem;
  background: url("../../assets/imgs/bg-btn.png") no-repeat 50%/cover;
  animation: sharke 1s linear infinite;
}

.page-pay .fixed-btn .fixed-submit:active {
  opacity: 1;
}

@keyframes sharke {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes infiniteScale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}